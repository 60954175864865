<template>
  <div class="card pr-2">
    <div class="text-center text-primary my-2"  v-if="isBusy">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Retrieving...</strong>
    </div>
    <div class="col p-3">
      <b-button class="row p-2 m-1 w-100 mh-25 bg-primary rounded">
        <i class="fa fa-file-text pr-1"></i>
        <strong>Open invoices:</strong> {{formatPrice(amounts.totalAmount)}}
        <br/>
        {{formatPrice(amounts.totalPastDue)}} Past due
        <i class="fa fa-warning pl-1"></i>
      </b-button>
      <a href="https://www.versapay.com/" target="_blank" class="btn row text-center p-2 m-1 w-100 mh-25 btn-secondary rounded">
        <i class="fa fa-credit-card pr-1"></i>Pay a bill and review documents
      </a>
      <a href="mailto:elcs@englandlogistics.com" class="btn row text-center p-2 m-1 w-100 mh-25 btn-secondary rounded">
        <i class="fa fa-phone pr-1"></i>Contact Billing
      </a>
    </div>
  </div>
</template>

<script>
import OrderService from "@/common/orders.service";

export default {
  mounted() {
    this.isBusy = true;
    OrderService.getInvoices()
    .then(data => {
      this.amounts = data;
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
        this.isBusy = false;
      }
    );
  },
  methods: {
    formatPrice(value) {
      if(value){
        return value.toLocaleString('en-US',
          {style: 'currency', currency: 'USD'}
        ); 
      }
    },
  },
  data() {
    return {
      amounts:{},
      isBusy: false,
    }
  }
}
</script>